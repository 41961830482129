/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Title } from '@mantine/core'
import { Stack } from '@mui/material'
import { MRT_PaginationState } from 'mantine-react-table'
import React, { useCallback, useEffect, useState } from 'react'
import { getListTrails } from 'src/apis/categoryTrailsAPI'
import TrailPickupItem from 'src/components/Trail/TrailPickupItem'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { color } from 'src/variable/constant'
import styles from './ModalTrails.module.scss'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import CustomModal from 'src/components/CustomComponents/CustomModal'
function ModalTrails({ open, onClose, trails, setTrails }) {
	const { openLoadingContext, closeLoadingContext, loadingContext } =
		useLoading()
	const { openError } = useModal()
	const [total, setTotal] = useState(1)
	const [data, setData] = useState([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState<null | string>(null)
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleGetTrails = useCallback(async () => {
		let arr = []
		openLoadingContext()
		try {
			const res = await getListTrails({
				fields: [
					'id',
					'user_id',
					'title',
					'start_address',
					'address',
					'description',
					'images',
					'level',
					'time_in_seconds',
					'height',
					'length',
					{ user: ['name', 'account_type'] },
				],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: value ? { title: value } : '',
			})
			if (res?.data?.code === 200) {
				arr = res?.data?.results?.objects?.rows?.map((item) => ({
					...item,
				}))
				setTotal(res?.data?.results?.objects?.count)
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalTrails.tsx:12 ~ handleGetTrails ~ error:',
				error,
			)
			openError(error)
		} finally {
			setData(arr)
			closeLoadingContext()
		}
	}, [JSON.stringify(pagination), value])
	useEffect(() => {
		handleGetTrails()
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetTrails()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])
	return (
		<CustomModal opened={open} onClose={onClose}>
			<Stack alignItems="center" sx={{ width: '100%' }}>
				<Title>Add trails</Title>
				<CustomClearInput
					value={value}
					onChange={(e) => setValue(e)}
					placeholder={'Search trails'}
				/>
				<br />
				<Stack
					sx={{
						width: '100%',
						padding: '10px',
						overflowY: 'auto',
						height: 'calc(100vh - 330px)',
						'::-webkit-scrollbar': {
							width: 4,
						},
						'::-webkit-scrollbar-track': {
							background: '#f1f1f1',
						},
						'::-webkit-scrollbar-thumb': {
							background: '#888',
							':hover': {
								background: '#555',
							},
						},
					}}
					spacing={2}
					className={styles.trailContainer}
				>
					{data?.map((item) => (
						<TrailPickupItem
							data={item}
							trails={trails}
							setTrails={setTrails}
						/>
					))}
				</Stack>
				<br />
				<Pagination
					color={color.CmsPrimary}
					position="center"
					total={Math.ceil(total / pagination.pageSize) || 1}
					onChange={(value) => {
						handleChangePage(value)
					}}
					value={pagination.pageIndex}
					disabled={loadingContext}
				/>
			</Stack>
		</CustomModal>
	)
}

export default React.memo(ModalTrails)
