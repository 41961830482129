import { Flex, Modal, Text } from '@mantine/core'
import { MRT_ColumnDef } from 'mantine-react-table'
import React, { useState } from 'react'
import { size } from 'src/variable/constant'
import { useNavigate } from 'react-router-dom'
import SettingPageList from 'src/containers/Settings/SettingPageList'

function SettingsPage() {
	const navigate = useNavigate()
	const [dataMore, setDataMore] = useState('')
	const openMore = (val) => {
		setDataMore(val)
	}
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'field',
			header: 'Field',
			size: size.zero,
		},
		{
			accessorKey: 'value',
			header: 'Value',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<div style={{ whiteSpace: 'normal' }}>
						<Text lineClamp={4}>
							<div
								dangerouslySetInnerHTML={{ __html: cell.getValue<string>() }}
							></div>
						</Text>
						<Text
							c="blue"
							onClick={() => openMore(cell.getValue<string>())}
							style={{ cursor: 'pointer' }}
						>
							Show more
						</Text>
					</div>
				)
			},
		},

		{
			accessorKey: 'created_at',
			header: 'Date create',
			size: size.zero,
			maxSize: size.text,
		},
	]

	const handleEdit = (row) => {
		navigate(`/settings/${row?.original?.id}`)
	}
	return (
		<Flex sx={{ width: '100%' }}>
			<SettingPageList columns={columns} handleEdit={handleEdit} />
			{dataMore && (
				<Modal
					opened={true}
					onClose={() => setDataMore('')}
					title="Content"
					size="auto"
					centered
					sx={{
						'.mantine-Modal-inner ': {
							width: '100%',
							padding: 200,
							boxSizing: 'border-box',
							paddingLeft: 260,
							'@media (max-width:900px)': {
								padding: 20,
							},
						},
					}}
				>
					<div dangerouslySetInnerHTML={{ __html: dataMore }}></div>
				</Modal>
			)}
		</Flex>
	)
}

export default React.memo(SettingsPage)
