import React from 'react'
import styles from './TrailItem.module.scss'
import { Checkbox, Flex, Image, Stack, Text } from '@mantine/core'
import cmsLogo from 'src/images/defaultAva.png'
import {
	IconChartColumn,
	IconCircleArrowUpFilled,
	IconClockFilled,
	IconRoute,
} from '@tabler/icons-react'
import { color } from 'src/variable/constant'
import { formatTime } from 'src/utils/utils'

interface TrailPickupItemProps {
	data: any
	trails: any[]
	setTrails: (_any) => void
	[key: string]: any
}
function TrailPickupItem({ data, trails, setTrails }: TrailPickupItemProps) {
	const handleOnChage = () => {
		const arr = [...trails]
		if (arr?.some((item) => item?.id === data?.id)) {
			setTrails(arr.filter((item) => item?.id !== data?.id))
		} else {
			setTrails((pre) => [...pre, data])
		}
	}
	return (
		<Stack
			spacing={8}
			sx={{
				padding: '5px 10px',
				boxShadow:
					'rgba(50, 50, 105, 0.15) 10px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px,rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset',
				borderRadius: '8px',
				position: 'relative',
			}}
		>
			<Flex
				sx={{ height: 'fit-content' }}
				align="center"
				gap={12}
				justify="space-between"
			>
				<Flex gap={12}>
					<Image
						src={
							data?.images[0]?.startsWith('https') ? data?.images[0] : cmsLogo
						}
						height={80}
						width={80}
						sx={{ img: { borderRadius: 16 } }}
						alt="trail pickup item img"
					/>
					<Stack spacing={0}>
						<Text
							size="md"
							weight={500}
							className={styles.lineClam}
							sx={{ paddingRight: 20 }}
						>
							{data?.title}
						</Text>
						<Text size="sm" className={styles.lineClam} color="dimmed">
							{data?.description}
						</Text>
					</Stack>
				</Flex>
				<Flex sx={{ position: 'absolute', top: 10, right: 10 }}>
					<Checkbox
						checked={trails?.some((item) => item?.id === data?.id)}
						onChange={handleOnChage}
						sx={{ input: { cursor: 'pointer' } }}
					/>
				</Flex>
			</Flex>
			<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
				<Flex gap={2}>
					<IconClockFilled
						style={{ color: color.BackgroundSuccessSecondary }}
					/>
					{formatTime(Number(data?.time_in_seconds || 0))}
				</Flex>
				<Flex gap={2}>
					<IconRoute style={{ color: color.BackgroundSuccessSecondary }} />
					{Number(data?.length || 0).toFixed(3)}Km
				</Flex>
				<Flex gap={2}>
					<IconCircleArrowUpFilled
						style={{ color: color.BackgroundSuccessSecondary }}
					/>
					{Number(data?.height || 0).toFixed(3)}ft
				</Flex>
				<Flex gap={2}>
					<IconChartColumn
						style={{ color: color.BackgroundSuccessSecondary }}
					/>
					{data?.level || ''}
				</Flex>
			</Flex>
		</Stack>
	)
}

export default React.memo(TrailPickupItem)
