import React from 'react'
import { Card, Text, Stack, Image, Flex, Checkbox } from '@mantine/core'
import cmsImage from 'src/images/cms.png'
import styles from './TrailItem.module.scss'
import {
	IconChartColumn,
	IconCircleArrowUpFilled,
	IconClockFilled,
	IconRoute,
} from '@tabler/icons-react'
import { formatTime } from 'src/utils/utils'
import { boxShadow, color } from 'src/variable/constant'
interface TrailItemProps {
	trail: any
	trails: any[]
	setTrails: (_any) => void
	sx?: any
	disabled?: boolean
	[key: string]: any
}
function TrailItem({
	trail,
	trails,
	setTrails,
	sx = {},
	disabled = false,
}: TrailItemProps) {
	const handleOnChage = () => {
		const arr = [...trails]
		if (arr?.some((item) => item?.id === trail?.id)) {
			setTrails(arr.filter((item) => item?.id !== trail?.id))
		} else {
			setTrails((pre) => [...pre, trail])
		}
	}
	return (
		<Card
			shadow="sm"
			padding="sm"
			radius="md"
			withBorder
			sx={{
				maxWidth: 'calc(25% - 10px)',
				minWidth: 'calc(25% - 10px)',
				maxHeight: 343 + 120,
				minHeight: 343 + 120,
				'@media (max-width:1200px)': {
					maxWidth: 'calc(33% - 10px)',
					minWidth: 'calc(33% - 10px)',
				},
				'@media (max-width:1000px)': {
					maxWidth: 'calc(50% - 10px)',
					minWidth: 'calc(50% - 10px)',
				},
				'@media (max-width:500px)': {
					maxWidth: 'calc(100% - 10px)',
					minWidth: 'calc(100% - 10px)',
				},
				...sx,
			}}
		>
			<Stack spacing="4px">
				<Flex sx={{ position: 'relative', marginBottom: 17 }}>
					<Image
						src={
							trail?.images?.[0]?.startsWith('https')
								? trail?.images?.[0]
								: cmsImage
						}
						height="240px"
						width="100%"
						sx={{
							img: {
								borderRadius: 16,
							},
						}}
					/>
					<Flex
						sx={{
							position: 'absolute',
							right: 0,
							bottom: 0,
							height: 35,
							width: 120,
							background: 'white',
							padding: '8px 16px',
							borderRadius: '20px',
							color: color.BackgroundSuccessSecondary,
							transform: 'translateY(50%)',
							boxShadow: boxShadow.primary,
						}}
						align="center"
						justify="center"
					>
						<IconChartColumn />
						<Text size="sm">{trail?.level}</Text>
					</Flex>
					{!disabled && (
						<Checkbox
							checked={trails?.some((item) => item?.id === trail?.id)}
							onChange={handleOnChage}
							sx={{
								position: 'absolute',
								top: 0,
								right: 0,
								input: { cursor: 'pointer' },
							}}
						/>
					)}
				</Flex>
				<Stack spacing="4px">
					<Text size="sm" className={styles.lineClam1}>
						{trail?.user?.account_type === 'GUIDER' ? 'Guider' : 'User'}:{' '}
						{trail?.user?.name}
					</Text>
				</Stack>
				<Text size="lg" weight={500} className={styles.lineClam}>
					{trail?.title}
				</Text>
				<Text size="sm" className={styles.lineClam} color="dimmed">
					{trail?.start_address}
				</Text>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<Flex>
						<IconClockFilled
							style={{ color: color.BackgroundSuccessSecondary }}
						/>
						{formatTime(Number(trail?.time_in_seconds || 0))}
					</Flex>
					<Flex>
						<IconRoute style={{ color: color.BackgroundSuccessSecondary }} />
						{Number(trail?.length || 0).toFixed(3)}Km
					</Flex>
					<Flex>
						<IconCircleArrowUpFilled
							style={{ color: color.BackgroundSuccessSecondary }}
						/>
						{Number(trail?.height || 0).toFixed(3)}ft
					</Flex>
				</Flex>
			</Stack>
		</Card>
	)
}

export default React?.memo(TrailItem)
