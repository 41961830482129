import React, { useCallback, useEffect, useState } from 'react'
import { Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import TableComponent from 'src/components/TableComponent'
import { SettingData } from 'src/interfaces'
import { formatDateTime } from 'src/variable/constant'
import { getListSetting } from 'src/apis/settingAPI'
import { useModal } from 'src/context/ModalContext'

interface UserPageListProps {
	columns: MRT_ColumnDef[]
	type?: string
	handleEdit: (row: any) => void
	[key: string]: any
}

function UserPageList({ columns, handleEdit }: UserPageListProps) {
	const [data, setData] = useState<SettingData[]>([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})

	const [total, setTotal] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const { openError } = useModal()
	const handleGetData = useCallback(async () => {
		let arr: SettingData[] = []
		let count: number = 0
		setLoading(true)
		try {
			const res = await getListSetting({
				fields: ['$all'],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
			})
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
				...item,
				created_at: dayjs(item?.created_at).format(formatDateTime),
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])

	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])

	return (
		<Stack sx={{ width: '100%' }}>
			<TableComponent
				columns={columns}
				total={total}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				handleEdit={handleEdit}
			/>
		</Stack>
	)
}

export default React.memo(UserPageList)
