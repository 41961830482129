export const color = {
	CmsPrimary: '#006B35',
	ColorBrandBlue: '#1E9037',
	BackgroundBrandHover: '#F5FAFF',
	BackgroundError: '#FE2E11',
	BackgroundDisable: '#E0DDDD',
	BackgroundBrandSecondary: '#F5FAFF',
	BackgroundSuccessSecondary: '#006B35',
	TextPrimary: '#212121',
	Orange: '#E55A0F',
	Green: '#006B35',
	Blue: '#2381FF',
	Black: 'black',
	BlueV2: '#00579B',
}

export const size = {
	no: 50,
	zero: 0,
	text: 200,
	mediumS: 250,
	medium: 350,
	long: 500,
}
export const textDecoration = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap' as 'nowrap',
}
export const userDataFields = [
	{ value: 'name', label: 'Name' },
	{ value: 'phone', label: 'Phone' },
	{ value: 'email', label: 'Email' },
	{ value: 'gender', label: 'Gender' },
	{ value: 'birthday', label: 'Birthday' },
	{ value: 'version', label: 'Version' },
	{ value: 'wallet', label: 'Wallet' },
	{ value: 'invite_code', label: 'Invite code' },
	{ value: 'created_at', label: 'Created at' },
	{ value: 'updated_at', label: 'Updated at' },
	{ value: 'last_login_at', label: 'Last login at' },
	{ value: 'about_me', label: 'About me', width: 100 },
	{ value: 'address', label: 'Address', width: 100 },
]
export const dateFields = [
	'updated_at',
	'created_at',
	'last_login_at',
	'birthday',
]
export const timestampFields = ['birthday']
export const formatDate = 'DD/MM/YYYY'
export const formatDateTime = 'DD/MM/YYYY HH:mm:ss'
export const formatDateTimeMinus = 'DD/MM/YYYY HH:mm'
export const passwordRules =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/
export const boxShadow = {
	primary: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
}
export const pathUrl = {
	home: '/',
	configphone: '/configphone',
	topics: '/topics',
	otpTracking: '/otptracking',
	notification: '/notification',
	service: '/service',
	conversation: '/conversation',
	province: '/province',
	network: '/network',
}
