import React from 'react'
import { useParams } from 'react-router-dom'
import UserDetail from 'src/containers/UserDetail'
import { userDataFields } from 'src/variable/constant'

function GuiderDetailPage() {
	const params = useParams()
	const { id } = params
	return (
		<UserDetail
			id={id}
			fields={userDataFields.filter((item) => item.value !== 'invite_code')}
			label="Guiders"
			goBack="/guiders"
		/>
	)
}

export default React.memo(GuiderDetailPage)
