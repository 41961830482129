import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import md5 from 'md5'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'

import * as authAPI from 'src/apis/authAPI'
import CustomInput from 'src/components/CustomComponents/CustomInput'
import { CustomStyles } from './Login.style'
import cmsImage from 'src/images/cms.png'
import styles from './Login.module.scss'
import { useLoading } from 'src/context/LoadingContext'
import CusTomButton from 'src/components/CustomComponents/CustomButton'
import { useModal } from 'src/context/ModalContext'
import { pathUrl } from 'src/variable/constant'
const Login = () => {
	const navigation = useNavigate()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openError } = useModal()
	const handleEmailChange = useCallback((e) => {
		setEmail(e.target.value)
	}, [])

	const handlePasswordChange = useCallback((e) => {
		setPassword(e.target.value)
	}, [])

	const handleTogglePasswordVisibility = useCallback(() => {
		setShowPassword((prevShowPassword) => !prevShowPassword)
	}, [])

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault()
			const body = {
				email: email,
				password: md5(password),
			}
			openLoadingContext()
			try {
				const res = await authAPI.login(body)
				const { data } = res
				if (data?.code === 200) {
					localStorage.setItem('info', JSON.stringify(data?.results?.object))
					localStorage.setItem(
						'refresh_token',
						JSON.stringify(data?.results?.refresh_token),
					)
					localStorage.setItem('token', JSON.stringify(data?.results?.token))
					setTimeout(() => {
						navigation(pathUrl.home)
					}, 0)
				}
			} catch (error) {
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[email, password, navigation],
	)
	return (
		<CustomStyles
			onSubmit={handleSubmit}
			autoComplete="off"
			sx={{
				background: '#211f29',
			}}
		>
			<Stack
				sx={{ width: '100%' }}
				spacing={3}
				className={styles.containerLogin}
			>
				<img src={cmsImage} alt="logo" />
				<Typography variant="h5" align="center" gutterBottom>
					Welcome to UniVini
				</Typography>
				<CustomInput
					label="Email"
					type="email"
					value={email}
					onChange={handleEmailChange}
					InputLabelProps={{
						shrink: true,
					}}
					inputProps={{ pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$' }}
					required
				/>
				<CustomInput
					label="Password"
					type={showPassword ? 'text' : 'password'}
					value={password}
					onChange={handlePasswordChange}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleTogglePasswordVisibility}>
									{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
								</IconButton>
							</InputAdornment>
						),
					}}
					required
				/>
				<CusTomButton
					fullWidth
					variant="outlined"
					color="primary"
					type="submit"
				>
					Login
				</CusTomButton>
			</Stack>
		</CustomStyles>
	)
}

export default React.memo(Login)
